import { countryCodes } from '../../constants/countryCodes'
import store from '../../core/store'
import { setCheckoutFinishedSuccess } from '../../reducers/orderSlice'

const Natura = function (paymentGateway) {
  this.paymentGateway = paymentGateway
  this.quote = null
  this.checkout = null
  this.orderId = null
  this.products = []

  this.createQuote = ({ order }) => {
    this.quote = order
  }

  this.startCheckout = async () => null

  this.goToCheckout = ({ checkoutWindow }) => {
    const basketItems = this.quote.orderDetails.map((orderDetail) => ({
      productId: orderDetail.product.sku,
      quantity: orderDetail.product.quantity,
    }))

    const basketJSON = JSON.stringify(basketItems)

    const { countryCode } = store.getState().store.current
    const countryCodesObject = Object.fromEntries(countryCodes.map((code) => [code, code]))
    const baseUrl =
      countryCode === countryCodesObject.AR
        ? '/mi-carrito'
        : countryCode === countryCodesObject.MX
        ? '/cart'
        : countryCode === countryCodesObject.PE
        ? '/mi-carro'
        : countryCode === countryCodesObject.BR
        ? '/sacola'
        : null
    const checkoutUrl = new URL(baseUrl, this.paymentGateway.urlCheckout)
    checkoutUrl.searchParams.set('sharebasket', basketJSON)

    const params = new URLSearchParams(checkoutUrl.search)

    params.set('utm_source', 'gojiraf')
    params.set('utm_medium', 'participant')
    params.set('utm_campaign', 'socios')

    checkoutUrl.search = params.toString()

    checkoutWindow.location.href = checkoutUrl.toString()
    checkoutWindow.focus()
    store.dispatch(setCheckoutFinishedSuccess(true))
  }
}

export default Natura
