import React from 'react'
import qs from 'qs'
import { useUtm } from '@gojiraf/useutm'

import { MainHeading } from './style'
import UtmUtils from '../../utils/utmUtils'
import { ChatAudienceCounter } from './ChatAudienceCounter'

export const ChatHeader = () => {
  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { utm_medium } = UtmUtils.getUtmObject(queryParams)
  const { isAllowedToJoinCall } = useUtm(utm_medium)

  return (
    <MainHeading data-test="main-heading-text">
      <span>Chat</span>
      {isAllowedToJoinCall === true ? <ChatAudienceCounter /> : null}
    </MainHeading>
  )
}
