import React from 'react'
import PropTypes from 'prop-types'

export const PoorNetWorkSVG = ({ width = '52', height = '44' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 52 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.9999 9.97602L43.5699 40.3327H8.42992L25.9999 9.97602ZM25.9999 0.666016L0.333252 44.9993H51.6666L25.9999 0.666016ZM28.3333 33.3327H23.6666V37.9993H28.3333V33.3327ZM28.3333 19.3327H23.6666V28.666H28.3333V19.3327Z"
        fill="black"
      />
    </svg>
  )
}

PoorNetWorkSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
}
