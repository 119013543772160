import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { integrations } from '@go-jiraf/sdk'
import { CircularProgress } from '@material-ui/core'

import { selectSeller } from '../../reducers/callSlice'

export function ChatAudienceCounter() {
  const { id: sellerId } = useSelector(selectSeller)

  const { data, status } = useQuery({
    queryKey: ['event-users-count', { channelName: sellerId }],
    queryFn: async () => integrations.agora.getUsersCount({ channelName: sellerId }),
    refetchInterval: 5000, // 5 seconds
  })

  if (status === 'loading') {
    return <CircularProgress />
  }

  if (status === 'error' || data === undefined) {
    return <span>&#40;&#45;&#41;</span>
  }

  return <span>&#40;{data.audience.total}&#41;</span>
}
